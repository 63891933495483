import React, { useEffect, useState } from "react";

export default function TestChoice(props) {
  const [value, setValue] = useState("");
  const [tmpValue, setTmpValue] = useState(true);

  useEffect(() => {
    setValue("");
    let answerList = sessionStorage.getItem("saveAnswer");

    if (answerList !== null) {
      let answer = answerList.split(",")[props.idx];
      setValue(answer.trim());
      setTmpValue(!tmpValue);
    }
  }, [props.idx]);

  const changeRadio = (e) => {
    setValue(e.target.value);
    setTmpValue(!tmpValue);
  };

  useEffect(() => {
    value && props.setCheckAnswer(value);
    return function cleanup() {
      props.setCheckAnswer("");
    };
  }, [value]);

  return (
    <div className="choices">
      {/* 사지선다 */}
      <label htmlFor="chosenAnswer"></label>
      <ul>
        {props.testOptions &&
          props.testOptions.map((choice, option) => (
            <label>
              <li
                key={option}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  type="radio"
                  name="chosenAnswer"
                  checked={
                    parseInt(value) === parseInt(option + 1) ? true : false
                  }
                  onChange={changeRadio}
                  value={option + 1}
                  className="form-control"
                />
                <span className="out-choices">
                  <span className="choices-number">
                    {" "}
                    &nbsp;{option + 1}.&nbsp;
                  </span>
                  {choice &&
                    choice.split("\\t").map((line) => {
                      return (
                        <span className="in-choices">
                          {line}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      );
                    })}
                </span>
              </li>
            </label>
          ))}
      </ul>
    </div>
  );
}
